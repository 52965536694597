<template>
    <div class="container">
        <b-modal title="Details" id="detailsModal" size="lg" no-close-on-esc hide-footer
                 @hidden="model={}">
            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <div class="row">
                        <div class="col-md-6">
                            <validated-input name="Name" label="Name" v-model="model.name" :rules="rules.name"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Email" label="Email" v-model="model.email"/>
                        </div>
                        <div class="col-md-12 form-group">
                            <b-form-file alternative name="Icon" label="Icon"
                                         v-model="model.logo" :rules="rules.logo"
                            />
                        </div>
                        <div class="col-md-12">
                            <validated-input name="Description" label="Description"
                                             v-model="model.description" :rules="rules.description"/>
                        </div>
                        <div class="col-md-12">
                            <validated-input name="Address" label="Address"
                                             v-model="model.address" :rules="rules.address"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="contact_number" label="contact_number"
                                             v-model="model.contact_number" :rules="rules.contact_number"
                                             @keypress="isNumber"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="whatsapp_number" label="whatsapp_number"
                                             v-model="model.whatsapp_number" :rules="rules.whatsapp_number"
                                             @keypress="isNumber"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="telegram_number" label="telegram_number"
                                             v-model="model.telegram_number" :rules="rules.telegram_number"
                                             @keypress="isNumber"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="signal_number" label="signal_number"
                                             v-model="model.signal_number" :rules="rules.signal_number"
                                             @keypress="isNumber"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="longitude" label="longitude"
                                             v-model="model.longitude" :rules="rules.longitude"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="latitude" label="latitude"
                                             v-model="model.latitude" :rules="rules.latitude"/>
                        </div>

                        <div class="col-md-12">
                            <b-button type="submit" variant="success">
                                Save
                            </b-button>
                        </div>

                    </div>
                </b-form>
            </validation-observer>
        </b-modal>
        <div class="row mt-4">
            <div class="col-md-12">
                <div class="card card-pricing border-0 text-center mb-4">
                    <div class="card-header bg-transparent clearfix">

                        <div class="float-left text-left col-md-10">
                            <h1>{{ shop[0].name }}
                                <img :src="shop[0].logo" style="max-width: 50px"
                                     alt=""></h1>
                            <span>{{ shop[0].description }}</span>
                        </div>
                        <div class="mt-3 float-right col-md-2 text-right">
                            <b-button variant="primary" class="btn btn-sm btn-icon-only rounded-circle"
                                      @click="editItem(shop)">
                                <i class="simple-icon-pencil"></i>
                            </b-button>
                            <b-button v-if="!shop[0].enabled" variant="success"
                                      class="btn btn-sm btn-icon-only rounded-circle"
                                      @click="changeStatus()">
                                <i class="ni ni-button-power"></i>
                            </b-button>
                            <b-button v-if="shop[0].enabled" variant="danger"
                                      class="btn btn-sm btn-icon-only rounded-circle"
                                      @click="changeStatus()">
                                <i class="ni ni-button-power"></i>
                            </b-button>
                        </div>

                    </div>
                    <div class="card-body">
                        <div class="mt-3 row">
                            <div class="col-3 pl-0">
                                <b-col>
                                    <h3>Contact Number</h3>
                                    <span>{{ shop[0].contact_number }}</span>
                                </b-col>
                                <b-col class="mt-3">
                                    <h3>Telegram</h3>
                                    <span>{{ shop[0].telegram_number }}</span>
                                </b-col>
                            </div>
                            <div class="col-3 pl-0">
                                <b-col>
                                    <h3>Email</h3>
                                    <span>{{ shop[0].email }}</span>
                                </b-col>
                                <b-col class="mt-3">
                                    <h3>Signal</h3>
                                    <span>{{ shop[0].signal_number }}</span>
                                </b-col>
                            </div>
                            <div class="col-3 pl-0">
                                <b-col>
                                    <h3>Whatsapp</h3>
                                    <span>{{ shop[0].whatsapp_number }}</span>
                                </b-col>
                            </div>
                            <div class="col-3 pl-0">
                                <b-col>
                                    <h3>Address</h3>
                                    <strong><span>{{ shop[0].address }}</span></strong><br>
                                    <span>Domain : {{ shop[0].domain }}</span><br>
                                    <span>latitude :{{ shop[0].latitude }} </span><br>
                                    <span>longitude : {{ shop[0].longitude }}</span><br>
                                </b-col>
                            </div>

                        </div>

                    </div>
                </div>

            </div>

        </div>

        <div>
            <b-tabs
                active-nav-item-class="font-weight-bold text-uppercase text-danger"
                active-tab-class="font-weight-bold"
                content-class="mt-3">
                <b-tab title="Orders" active>
                    <orders></orders>
                </b-tab>
                <b-tab title="Catalog">
                    <shop-catalog></shop-catalog>
                </b-tab>

            </b-tabs>
        </div>

    </div>

</template>

<script>
import axios from 'secure-axios';
import urls from '@data/urls';
import ShopCatalog from './ShopCatalog/ShopCatalog';
import Orders from '@/views/SuperAdmin/Shops/Orders/Orders';

export default {
    name: 'ShopDetails',
    components: { Orders, ShopCatalog },
    data () {
        return {
            shop: [],
            products: [],
            detailsModel: {
                id: '',
                name: '',
                email: '',
                phone: ''
            },
            model: {
                id: this.$route.params.id,
                owner: '',
                enabled: '',
                name: '',
                email: '',
                logo: null,
                description: '',
                address: '',
                contact_number: '',
                whatsapp_number: '',
                telegram_number: '',
                signal_number: '',
                latitude: '',
                longitude: ''
            },
            rules: {
                name: {
                    required: true
                },
                // address_type: {
                //     required: true
                // },
                address: {
                    required: true
                },
                logo: {
                    required: true
                },
                contact_number: {
                    required: true
                },
                whatsapp_number: {
                    required: true
                },
                pin_code: {
                    required: true
                }
            }
        };
    },
    beforeMount () {
        this.loadData();
    },
    methods: {
        openModal () {
            this.$bvModal.show('detailsModal');
        },

        setData (response) {
            if (response.data.shop) {
                this.products = response.data.data;
                console.log('this.products', this.products);
                this.shop = response.data.shop;
                console.log('this.shop', this.shop);
            }
        },

        setShopData (response) {
            if (response.data.obj) {
                this.shop.splice(0, this.shop.length, response.data.obj);
                console.log('response.data.obj', response.data.obj);
            }
        },

        async onSubmit () {
            const url = urls.shop.update;
            const response = await axios.form(url, this.model);
            if (response.data.error) {
                this.$refs.formValidator.setErrors(response.data.errors);
            } else {
                this.setShopData(response);
                this.$bvModal.hide('detailsModal');
            }
        },
        async loadData () {
            const response = await axios.post(urls.shopProduct.shopdetailsadmin + '?id=' + this.$route.params.id);
            this.setData(response);
        },

        async editItem (rowData) {
            console.log(rowData);
            this.model = {
                id: rowData[0].id,
                owner: rowData.owner,
                name: rowData[0].name,
                email: rowData[0].email,
                description: rowData[0].description,
                contact_number: rowData[0].contact_number,
                address: rowData[0].address,
                whatsapp_number: rowData[0].whatsapp_number,
                telegram_number: rowData[0].telegram_number,
                signal_number: rowData[0].signal_number,
                domain: rowData[0].domain,
                latitude: rowData[0].latitude,
                longitude: rowData[0].longitude
            };
            this.openModal();
        },
        changeStatus () {
            const that = this;
            const id = this.$route.params.id;
            if (!confirm('Are you sure?')) {
                return;
            }
            axios.form(urls.shop.EnableOrDisable, {
                id: id
            }).then(function (data) {
                console.log('data data', data.data);
                console.log('that.shop that.shop', that.shop);
                const arr = that.shop;
                const newArr = data.data.data;
                arr.splice(0, arr.length, ...newArr);
            });
        },
        isNumber: function (evt) {
            evt = (evt) || window.event;
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
                ;
            } else {
                return true;
            }
        }

    }
};
</script>

<style scoped>

</style>
